@mixin btn--gradient($color, $contrast-color: color-contrast($color)) {
    color: $contrast-color;
    background: linear-gradient(65deg,$color 0,lighten($color, 10%) 100%);
    box-shadow: 0 8px 24px rgba($color,.25);
    &:active,
    &:focus,
    &:hover {
        color: $contrast-color;
        box-shadow: 0 8px 16px rgba($color,.4);
    }
}

@mixin btn--shadow($color) {
    color: $color;
    background: transparent;
    box-shadow: 0 8px 24px rgba($color,.25);
    &:active,
    &:focus,
    &:hover {
        color: $color;
        box-shadow: 0 8px 16px rgba($color,.4);
    }
}
