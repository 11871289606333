.impersonate-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  &__inner {
    position: relative;
    .btn-impersonate {
      position: absolute;
      bottom: 100%;
      right: 10vw;
      color: $light;
      background-color: rgba($dark, .6);
      border: none;
      outline: none;
      padding: 2px 6px;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
  }
}
#toggleImpersonate {
  padding: 15px;
  text-align: center;
  color: $light;
  background-color: rgba($dark, .6);
}
