@import "../../common/components/impersonate";

@import "components/box";
@import "components/carousel";
@import "components/nav";
@import "components/product";

@import "layout/footer";
@import "layout/header";
@import "layout/sidebar";

/*********************************************/

//@include media-breakpoint-up(xl) {
//  .container, .container-sm, .container-md, .container-lg, .container-xl {
//    max-width: 80%;
//  }
//}
body{
  background: url('../../../../public/images/frontend/background.jpg') no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  display:flex;
  flex-direction: column;
}
.container{
  @media (min-width: 1200px){
    max-width: 1025px;
  }
}
.fs--small {
  font-size: .75rem;
}

.swal2-loader {
  border-color: $primary transparent $primary transparent !important;
}

label {
  font-weight: 500;
}

.block--underline {
  border-bottom: 1px solid;
}

.list-icon {
  > li {
    position: relative;
    padding-left: 0;

    > i:first-child {
      color: $primary;
      font-size: 3rem;
    }
  }

  @include media-breakpoint-down(xs) {
    > li {
      text-align: center;
    }
  }
  @include media-breakpoint-up(md) {
    > li {
      padding-left: 4rem !important;

      > i:first-child {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

.list-group-item {
  background: none;
  border-color: $white;
}

.list-group-flush > .list-group-item {
  border-bottom-width: 2px;
}

#content {
  //padding-top: 30px;
  //@extend .bg-light-brown;
  .form-control{
    padding: 0.75rem 0.75rem;
    font-size: 1.2rem;
  }
}

.section__title {
  color: $primary;
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
  //margin-bottom: 3rem;
  > span:not(.no-line) {
    display: inline-block;

    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 30%;
      margin: .5rem auto 0;
      background-color: $primary;
    }
  }
}

.section__subtitle {
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
}

.searchbar {
  //max-width: 800px;
  margin: 0 auto;

  .form-group {
    box-shadow: 0 1px 5px #e3e7eb;

    input {
      font-size: 1rem;
      border: 1px solid #ececec;
      box-shadow: 1px 0 5px rgba(131, 131, 131, .13);

      &:focus {
        box-shadow: 6px 0 24px rgba(131, 131, 131, .4);
      }
    }
  }

  button {
    border: none;
    background: none;
    color: $primary;
    font-size: 1.5rem;
  }
}

.bg {
  &-gray {
    background-color: $gray-100;
  }
}

.text {
  &-gray {
    color: $gray-100;
  }
}

.btn {
  border-radius: 1.5rem;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height:1em;

  &--cart {
    display: inline-flex;
    align-items: center;
    line-height: 1;
    font-size: .75rem;
    text-align: left;
    i {
      font-size: 1.4em;
    }
  }

  &--icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.4em;
    height: 1.4em;
    line-height: 1;
    background-color: $white;
    color: $primary;
    border-radius: 50%;
    font-size: 1.7rem;
  }

  &--close {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 1rem;
    border: none;
    background: none;
    color: $white;
    padding: 10px;
  }

  &--gradient {
    &-primary {
      //@include btn--gradient($primary, $white);
      color: $white;
      background-color: $primary;
      //box-shadow: 0 8px 24px rgba($color,.25);
      &:active,
      &:focus,
      &:hover {
        color: $white;
        background-color: $secondary;
        box-shadow: 0 8px 16px rgba($secondary,.4);
      }
    }
  }

  &--gradient {
    &-secondary {
      @include btn--gradient($secondary, $white);
    }
  }

  &--gradient {
    &-light {
      @include btn--gradient($light);
    }
  }

  &--shadow {
    &-primary {
      @include btn--shadow($primary);
    }
  }
}

.pagination {
  //display: flex;
  //list-style: none;
  //color: $body-color;
  //margin: 0;
  > .page-item {
    font-size: .875rem;
    font-weight: 400;

    > .page-link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1.75em;
      height: 1.75em;
      border: none;
      color: $body-color;
      border-radius: 50%;
      line-height: 1;
    }

    &.active {
      > .page-link {
        color: color-contrast($primary);
        font-weight: 600;
      }
    }

    + .page-item {
      margin-left: 5px;
    }
  }
}

.modal-header {
  //border-bottom: none;
  .modal-title {
    @extend .section__title;
  }
}

.table {
  tfoot {
    td:not(.empty) {
      font-weight: 600;
      text-transform: uppercase;
      color: $white;
      background-color: $secondary;
      border-bottom: 1px solid #dee2e6;
    }
  }
  tr {
    &.active {
      color: $primary;
      font-weight: bold;
    }
    th {
      &[scope="row"] {
        background-color: $secondary;
        color: $light;
      }
    }
  }
}

.form-control,
.form-select {
  //border: none;
  border-radius: 0;
  /*border-bottom: 2px solid $input-border-color;*/
  box-shadow: none !important;
  text-align: center;
}

.dropdown-menu {
  border: none;
  box-shadow: 0 1px 5px rgba($black, .25);
  max-height: 250px;
  overflow-y: scroll;

  .dropdown-item {
    color: $body-color;
    text-transform: uppercase;
    font-weight: 500;
    white-space: break-spaces;

    &.active,
    &:active,
    &:focus,
    &:hover {
      background: none;
      color: $primary;
    }
  }
}

.ope {
  &__rules {
    &__title {
      font-size: 1.2em;
      font-weight: 600;
    }
    &__subtitle {
      font-size: 1em;
      font-weight: 600;
    }
  }
}

.ope-result {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;

  &__stats {
    color: $success;
    font-size: 1.5rem;
    font-weight: 600;

    &__points {
      font-size: 2em;
      font-weight: 900;
      line-height: 1;
    }

    &__separator {
      font-weight: 400;
      margin: 0 .5rem;
    }
  }

  &__date__label {
    display: inline-block;
    padding: 0 .3em;
    font-size: .875rem;
    font-weight: 600;
    color: $white;
    background-color: $success;
  }
}

.cart-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  i {
    font-size: 2em;
  }
}

.cart-line {
  td {
    vertical-align: middle;
  }
  &__counter {
    width: 50px;
    text-align: center;
    background: none !important;
    border: none;
  }
  &__btn-add,
  &__btn-remove {
    padding: 0.5em;
    line-height: 1;
  }
}

.dropdown-large{ padding:1rem; }

@media all and (min-width: 992px) {
  .dropdown-large{min-width:500px;}
}
