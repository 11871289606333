.product {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  font-size: .75rem;
  border-radius: $border-radius;
  box-shadow: 0 1px 5px rgba($black, .2);
  transition: box-shadow ease .25s;
  &:hover {
    box-shadow: 0 2px 14px rgba($black, .35);
  }
  &--no-shadow {
    box-shadow: none !important;
    .product__image {
      cursor: default;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    &__points {
      font-weight: 600;
      color: $primary;
    }
  }
  &__image {
    position: relative;
    margin: 1rem 0;
    cursor: pointer;
    &:before {
      content: '';
      display: block;
      padding-top: 75%;
    }
    img,
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    img {
      object-fit: scale-down;
      object-position: center;
    }
    &__overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      background-color: rgba($white, .8);
      opacity: 0;
      transition: opacity .25s ease;
      font-size: 3rem;
    }
    &:hover {
      .product__image__overlay{
        opacity: 1;
      }
    }
  }
  &__content {
    flex: 1;
    padding: 0.8rem 0;
    border-top: 1px solid rgba($body-color, .2);
    &__title {
      margin: 0;
      font-size: .875rem;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
    }
    &__description {
      margin: .25rem 0 0 0;
    }
  }
  //&__footer {
  //  padding: 12px 0 2px 0;
  //  text-align: center;
  //  text-transform: uppercase;
  //}
  //&__add-to-cart {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  font-weight: 600;
  //  font-size: .875rem;
  //  color: $primary;
  //  .ico {
  //    font-size: 1.8em;
  //    margin-right: 5px;
  //  }
  //}
  //&--convert {
  //  .product {
  //    &__image {
  //      flex: 1;
  //      text-align: center;
  //      cursor: default;
  //      &:before {
  //        content: none;
  //      }
  //      img {
  //        position: relative;
  //        top: auto;
  //        left: auto;
  //        width: auto;
  //        height: auto;
  //        object-fit: none;
  //      }
  //    }
  //  }
  //}

  &__price {
    text-align: center;
    font-weight: bold;
    color: $primary;
    font-size:1.4rem;
  }
}
