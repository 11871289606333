.nav {
  &--themed {
    .nav-item {
      font-size: 1.6rem;
      font-weight: 900;
      text-transform: uppercase;
      &.active {
        .nav-link {
          color:$white;
          background: $primary;
          border-radius: 0 30px 30px 0;
          &:before {
            font-size:1.5em;
            padding-left:8px;
            content: '✗';
            display:block;
            height:100%;
            position:absolute;
            left:-23px;
            top:0;
            border-radius: 30px 0 0 30px;
            color: $white;
            background: $primary;
          }
        }
      }
      .nav-link {
        color: $dark;
        display:inline-block;
        position:relative;
      }
    }
  }
  &.ope__levels {
      justify-content: space-between;
      align-items: center;
    background: $gray-900;
    border-radius: 25px;
    .ope__level {
      height:100%;
      &:not(:first-of-type) {
        border-left: 1px solid $gray-500;
      }
      &:first-of-type .nav-link {
        border-radius: 25px 0 0 25px;
      }
      &:last-of-type .nav-link {
        border-radius: 0 25px 25px 0;
      }
      .nav-link {
        padding: 5px 0;
        display: block;
        text-align: center;
        border:none;
        margin: 0;
        border-radius: 0;
        width: 100%;
        height:100%;
        text-decoration: none;
        color: $gray-500;
        &.active {
          background: $secondary;
          color: $white;
        }
      }
    }
  }
}
