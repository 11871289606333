/* ---------------------------------------------------
    OFFCANVAS ESSENTIALS
----------------------------------------------------- */
.offcanvas-start {
  width: 250px;
}
/* Desktop view */
@media (min-width: 576px) {
  .main-content {
    &--with-offcanvas {
      padding-left: 250px;
      margin-left: inherit;
    }
  }
  .offcanvas-backdrop {
    display: none;
  }
}

/* ---------------------------------------------------
    SIDEBAR STYLES
----------------------------------------------------- */

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  border: 0;
  &:hover, &:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #e5e5e5;
  }
  &::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
  }
  &[aria-expanded="true"] {
    color: rgba(0, 0, 0, .85);
    &::before {
      transform: rotate(90deg);
    }
  }
  &-nav {
    a {
      display: inline-flex;
      padding: .1875rem .5rem;
      margin-top: .125rem;
      margin-left: 1.25rem;
      text-decoration: none;
      &:hover, &:focus {
        background-color: #e5e5e5;
      }
      &.active {
        font-weight: bold;
      }
    }
  }
}
.offcanvas .offcanvas-body > ul {
   > .nav-item:hover, > .nav-item.active {
    background-color: $primary;
    .nav-link {
      color: $white;
    }
  }
}
