#clientInfos {
  line-height: 1.4;
  font-weight: 500;
  font-size: .8rem;
}

#clientRemainingPoints {
  .list-group {
    background: $primary;
    font-weight: bold;
    > .list-group-item {
      border: 0;
      color: $white;
    }
    .client-infos {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0.5rem;
      line-height: 1;
      &__label {
        font-size: .75rem;
      }
      &__value {
        font-weight: 900;
        margin-left: 1rem;
      }
    }
  }
}
