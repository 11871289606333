.carousel {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  box-shadow: 0 1px 5px rgba($black, .2);
  padding: 1rem 1.5rem;
  @include media-breakpoint-up(md) {
    height: 100%;
    min-height: 325px;
    &-inner {
      flex: 1;
    }
  }
  &-indicators {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    display: flex;
    list-style: none;
    color: $body-color;
    margin: 0;
    [data-bs-target] {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1.75em;
      height: 1.75em;
      border-radius: 50%;
      font-size: .875rem;
      font-weight: 400;
      text-indent: 0;
      border: none;
      margin: 0;
      &.active {
        background-color: $primary;
        color: color-contrast($primary);
        font-weight: 600;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $primary;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  &__footer {
    border-top: 1px solid rgba($body-color, .2);
    text-align: center;
    margin-top: 1rem;
    padding-top: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .875rem;
    a {
      color: $body-color;
      text-decoration: underline;
    }
  }
}

.product-slide {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__title {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  &__image {
    text-align: center;
    position: relative;
    cursor: pointer;
    @include media-breakpoint-up(md) {
      &:before {
        content: '';
        display: block;
        padding-top: 75%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        object-position: center;
      }
    }
  }
  &__points {
    color: $primary;
    font-weight: 600;
    font-size: 1.25rem;
  }
  &__content {
    flex: 1;
  }
  &__footer {
    text-align: center;
  }
}