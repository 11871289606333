@import "fonts";
@import "variables";
@import "mixins";
@import "animations";

@import "components/button";
@import "components/form-wizard";
@import "components/impersonate";
@import "components/table";
@import "components/offcanvas";

/*********************************************/

label.required:after {
  content: " *";
}

.bg-{
  &gray {
    background-color: $gray !important;
  }
  &dark-gray {
    background-color: $dark-gray !important;
  }
}
