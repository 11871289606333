#topbar {
  //text-align: center;
  padding-top: 2.5rem;
  //background-image: url('/public/images/banner.png');
  background-size: cover;
  background-position: bottom center;
}

#header {
  background: linear-gradient(65deg, $secondary 0, lighten($secondary, 10%) 100%);
  box-shadow: 0 2px 20px rgba(darken($secondary, 12.5%), .25) !important;
  //box-shadow: 0 3px 6px 3px rgba($black, .1);
  .navbar-toggler {
    font-size: 2rem;
    border: none;
    color: $white;
  }

  .navbar {
    background: none;
    //background-color: $secondary;
    color: $white;
    padding: 0;

    .navbar-nav {
      .nav-link {
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.4rem;
        //box-shadow: inset 0px -30px 30px -40px rgba($black, .8);
        &:hover {
          color: $secondary;
          background-color: $white;
        }

        i {
          font-size: 3em;
          margin-bottom: .5rem;
        }
      }

      .nav-item {
        &.active {
          .nav-link {
            color: $white;
            background-color: $secondary;
            //box-shadow: inset 0px -30px 30px -40px lighten($secondary, 35%);
          }
        }
      }
    }
  }
}

//#sidebar {
//  position: fixed;
//  top: 0;
//  right: -250px;
//  width: 250px;
//  transition: right ease .25s;
//  z-index: 9999;
//
//  &.active {
//    right: 0;
//
//    .sidebar {
//      &__overlay {
//        display: block;
//        opacity: 1;
//      }
//    }
//  }
//
//  .nav {
//    text-align: center;
//
//    .nav-link {
//      margin-top: 1rem;
//      text-transform: uppercase;
//      font-weight: 500;
//
//      i {
//        font-size: 2.5em;
//        margin-bottom: .5rem;
//      }
//    }
//  }
//}
//
//.sidebar {
//  &__container {
//    position: relative;
//  }
//
//  &__wrapper {
//    position: relative;
//    min-height: 100vh;
//    padding: 0 15px;
//    text-align: center;
//    background-color: $white;
//    z-index: 1;
//  }
//
//  &__overlay {
//    display: none;
//    position: fixed;
//    top: 0;
//    right: 0;
//    width: 100vw;
//    height: 100vh;
//    background-color: rgba(0, 0, 0, .5);
//    opacity: 0;
//    transition: opacity ease .25s;
//  }
//}