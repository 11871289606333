$stimactiv : #be2975;
$cui : #EE7203;
$samse-blue : #00BAD9;
$samse-violet : #BE0185;

$primary: $samse-blue;
$secondary: $samse-violet;

$font-family-sans-serif: 'Montserrat', sans-serif;

$border-radius-sm: .25rem;
$border-radius: .3rem;
$border-radius-lg: .35rem;