/*************************************************************/

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animated.infinite {
    animation-iteration-count: infinite;
}

.animated.hinge {
    animation-duration: 2s;
}

.animated.flip-out-x,
.animated.flip-out-y,
.animated.bounce-in,
.animated.bounce-out {
    animation-duration: .75s;
}

@keyframes Floatingy{
    from {transform:translate(0, 0px);}
    65% {transform:translate(50px, 0);}
    to {transform: translate(0, -0px);}
}
@keyframes Floatingx{
    from {transform:translate(50px, 0px);}
    65% {transform:translate(0, 0);}
    to {transform: translate(50px, -0px);}
}

@keyframes bounce {
    from, 20%, 53%, 80%, to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 0, 0);
    }
    40%, 43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -30px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -15px, 0);
    }
    90% {
        transform: translate3d(0, -4px, 0);
    }
}
.bounce {
    animation-name: bounce;
    transform-origin: center bottom;
}

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
.pulse {
    animation-name: pulse;
}

@keyframes flash {
    from, 50%, to {
        opacity: 1;
    }
    25%, 75% {
        opacity: 0.2;
    }
}
.flash {
    animation-name: flash;
    animation-duration: 3s;
}

@keyframes wave-animate {
    0% {
        transform: scale(0);
        opacity: 0.8;
        transform-origin: center;
    }
    100% {
        transform: scale(3);
        opacity: 0;
        transform-origin: center;
    }
}

@keyframes color-loop {
    0% {
        color: #ffffff;
    }
    //25% {
    //    color: #6bc8da;
    //}
    50% {
        color: #4286F3;
    }
    //75% {
    //    color: #6bc8da;
    //}
    100% {
        color: #ffffff;
    }
}