.footer {
  &__link {
    font-weight: bold;
  }
  &__logo {
    width: 200px;
  }
  &__bottom {
    a {
      color: $white;
    }
  }
}

#footer {
  .nav {
    color: $white;
    font-size: .7rem;
    font-weight: 500;
    align-items: center;
    .nav-item {
      display: flex;
      align-items: center;
      .nav-link {
        display: inline-block;
        padding: 0;
        &:hover{
          color: $secondary;
        }
      }
      + .nav-item {
        margin-left: 1rem;
        &:before {
          content: "\2022";
          font-size: 1rem;
          margin-right: 1rem;
          line-height: 1;
        }
      }
    }
  }
}