.box-rubrique {
  position: relative;
  display: block;
  border-radius: $border-radius;
  box-shadow: 0 1px 5px rgba($black, .2);
  transition: box-shadow ease .25s;
  &:hover {
    box-shadow: 0 2px 14px rgba($black, .35);
  }
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  @media (max-width: 575px){
    max-height: 125px;
  }
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: .5rem;
  }
  &__image {
    flex: 1;
    text-align: center;
    height: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 100%;
    }
  }
  &__content {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.8rem;
    text-align: center;
    color: $primary;
  }
}

.box-operation {
  display: block;
  position:relative;
  transition: all .3s ease;
  &__wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      padding-top: 80%;
    }
  }
  &--banner {
    @include media-breakpoint-up(md) {
      .box-operation__wrapper {
        position: relative;
        overflow: hidden;
        &:before {
          padding-top: 35%;
        }
      }
    }
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .3s linear;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__status {
    color: $white;
    text-align: center;
    font-weight: 700;
    position:absolute;
    z-index:10;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background: rgba(255,255,255,0.7);
    &.operation--open {
      background: none;
    }
    &:after {
      height:50px;
      margin-top:calc(50% - 55px);
      display:inline-block;
      padding: 0 5px;
      font-size:2rem;
      font-weight:bolder;
      transform: rotate(-20deg);
      border: 3px solid $primary;
      line-height: 45px;
    }
    &.operation {
      &--upcoming {
        &:after {
          color: $primary;
          border-color: $primary;
          content: 'A VENIR';
        }
      }
      &--close {
        &:after {
          color: $secondary;
          border-color: $secondary;
          content: 'TERMINÉ';
        }
      }
    }
  }
  &__content {
    flex: 1;
    position: relative;
    padding: 15px;
    text-align: center;
  }
  &:hover {
    text-decoration: none;
    box-shadow: 0px 5px 15px rgba($black, .2);
    .box-operation__image {
      transform: scale(1.02);
    }
  }
}
